.About {
  margin-top: 100vh;
  background: white;
}

.Video {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  margin: 5rem auto;
  height: 0;

  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

