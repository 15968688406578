.Collaborations {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  background: #e5e5e5;

  img {
    flex: 0 0 30%;
    width: 15rem;
    height: auto;
    margin: 2rem 0;
  
    @media screen and (min-width: 480px) {
      width: 10rem;
    }
    @media screen and (min-width: 768px) {
      width: 10rem;
      flex: 0 0 18%;
    }
    @media screen and (min-width: 1000px) {
      width: 12rem;
    }
    @media screen and (min-width: 1400px) {
      width: 15rem;
    }


  }
}

