.Header {
  position: fixed;
  top: 0;
  height: 7.5rem;
  width: 100%;
  z-index: 10;
  transition: .3s;

  > div {
    height: 100%;
  }

  .Logo {
    height: 4rem;
    width: auto;
  }

  nav a {
    text-decoration: none;
    color: white;
    font-weight: bold;
  }

}

.Hamburger {
  width: 40px;
  height: 32px;
  position: relative;
  z-index: 10;
  margin: 50px auto;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;

  span {
    display: block;
    position: absolute;
    height: 6px;
    width: 50%;
    background: black;
    opacity: 1;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;

    &:nth-child(even){
      left: 50%;
      border-radius: 0 9px 9px 0;
        }
    &:nth-child(odd){
      left:0px;
      border-radius: 9px 0 0 9px;
    }

    &:nth-child(1), &:nth-child(2) {
      top: 0px;
    }
    &:nth-child(3), &:nth-child(4) {
      top: 13px;
    }
    &:nth-child(5), &:nth-child(6) {
      top: 26px;
    }
    
  }
}

.open span:nth-child(1),.open span:nth-child(6) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.open span:nth-child(2),.open span:nth-child(5) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.open span:nth-child(1) {
  left: 5px;
  top: 7px;
}

.open span:nth-child(2) {
  left: calc(50% - 5px);
  top: 7px;
}

.open span:nth-child(3) {
  left: -50%;
  opacity: 0;
}

.open span:nth-child(4) {
  left: 100%;
  opacity: 0;
}

.open span:nth-child(5) {
  left: 5px;
  top: 18px;
}

.open span:nth-child(6) {
  left: calc(50% - 5px);
  top: 18px;
}

nav ul {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  background: rgba(255,255,255,0.98);
}
nav ul.open {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  a {
    margin: 2rem 0;
    color: black
  }

}


@media screen and (min-width: 769px) {
  .Header {
    height: 10rem;
  }

  .Hamburger {
    display: none;
  }

  nav ul, nav ul.open {
    display: block;
    position: relative;
    height: 100%;
    width: 100%;
    margin: 0;
    background: none;

    a {
      margin-left: 1.5rem;
    }
  }
}