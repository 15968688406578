.Press {
  position: relative;
  padding: 0;
  overflow: hidden;

  .content {
    position: relative;
    width: 100%;
    padding: 2rem;
    box-sizing: border-box;
    background: white;
    z-index: 1;

    h2 {
      padding-left: 1rem;
    }

    a {
      font-family: 'HeroFont';
      color: black;
      text-decoration: none;
      margin: 1rem 0;
      padding: 1rem;

      &:hover {
        background: black;
        color: white;
      }
    }
    
    @media screen and (min-width: 768px) {
      width: 60%;
      padding: 2rem 7.5%;
      box-sizing: border-box;
      margin: 0 10%;
      clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%);
    }  
  }
}
