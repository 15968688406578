.Contact {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  background: white;
  text-align: center;

  @media screen and (min-width: 768px) {
    text-align: left;
  }
}

.Form {
  width: 100%;
  padding-bottom: 2.5rem;
  
  @media screen and (min-width: 768px) {
    width: 60%
  }
}

h2 {
  width: 100%;
}
h3 {
  margin-top: 0;
}

input, textarea {
  width: 100%;
  margin-bottom: 1rem;
  padding: 1rem;
  box-sizing: border-box;
}

textarea {
  height: 10rem;
}

button {
  padding: 1.5rem 3rem;
  cursor: pointer;
  float: right;
}
