.Hero {
  position: fixed;
  height: 100vh;
  min-height: 100vh;
  top: 0;
  z-index: -1;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  text-align: center;
  background-position: center right;
  color: white;

  * {
    width: 100%;
  }

  h1 {   
    font-size: 4.75rem;

    @media screen and (min-width: 768px) {
      font-size: 10rem;
    }
  }

  h2 {   
    font-family: 'BaseFont';
  }
}

