@font-face {
  font-family: 'HeroFont';
  src: local('HeroFont'), url(./fonts/Lobster-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'BaseFont';
  src: local('BaseFont'), url(./fonts/BarlowSemiCondensed-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'BaseFont_Bold';
  src: local('BaseFont_Bold'), url(./fonts/BarlowSemiCondensed-Medium.ttf) format('truetype');
}

html {
  font-size: 62.5%;
  height: 100%;
  width: 100%;
}
body {
  height: 100%;
  width: 100%;
  margin: 0;
  font-family: 'BaseFont';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.6rem;

  #root {
    height: 100%;
    width: 100%;
  }
}

h1 {
  font-family: 'HeroFont';
  font-size: 5rem;
}
h2 {
  font-family: 'HeroFont';
  font-size: 2.5rem;
}
h3 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
h5 {
  margin-top: 0;
  margin-bottom: 1rem;
}

p {
  margin: 0;
  padding: 0;
  margin-bottom: 1.5rem;
}

.scrolled {
  height: 5rem !important;
  background: white;
  
  nav.Navigation a {
    color: black;
  }
}

.center {
  text-align: center;
}

.Container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 120rem;
  padding: 2rem;
  box-sizing: border-box;
  margin: 0 auto;
}

.content {
  max-width: 90rem;
  margin: 0 auto;
  z-index: 1;
}

.list {
  * {
    display: block;
    margin: 2rem 0;
  }
}

.section-image {
  position: relative;
  width: 100%;
  height: auto;
}

.button, a.button {
  padding: 1.5rem 3rem;
  border: 2px solid black;
  border-radius: 2rem;
  display: inline-block;
  background: none;
  font-family: 'HeroFont';

  &:hover {
    border: 2px solid #f1f1f1;
    background: black;
    color: white;
  }
}


section {
  width: 100%;
  padding: 5rem 2rem;
  box-sizing: border-box;
  background-size: cover;
  background-position-y: center;
}

footer {
  padding: 1rem 2rem;
  color: white;
  background: black;
}

@media screen and (min-width: 769px) {
  .scrolled {
    height: 9rem !important;
  }

  .columns {
    columns: 2;
  }  

  .section-image {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 0;
  }
  
}